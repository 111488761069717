import * as types from '../types';

export function loginRequest(payload) {
  return {
    type: types.LOGIN_REQUEST,
    payload,
  };
}

export function loginSuccess(payload) {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
}

export function loginFailure(payload) {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
}

export function editOtherUserRequest(payload) {
  return {
    type: types.EDIT_OTHER_USER_REQUEST,
    payload,
  };
}

export function editOtherUserSuccess(payload) {
  return {
    type: types.EDIT_OTHER_USER_SUCCESS,
    payload,
  };
}

export function editOtherUserFailure(payload) {
  return {
    type: types.EDIT_OTHER_USER_FAILURE,
    payload,
  };
}

export function editRequest(payload) {
  return {
    type: types.EDIT_REQUEST,
    payload,
  };
}

export function editSuccess(payload) {
  return {
    type: types.EDIT_SUCCESS,
    payload,
  };
}
export function editFailure(payload) {
  return {
    type: types.EDIT_FAILURE,
    payload,
  };
}
export function editCourseRequest(payload) {
  return {
    type: types.EDIT_COURSE_REQUEST,
    payload,
  };
}

export function editCourseSuccess(payload) {
  return {
    type: types.EDIT_COURSE_SUCCESS,
    payload,
  };
}
export function editCourseFailure(payload) {
  return {
    type: types.EDIT_COURSE_FAILURE,
    payload,
  };
}

export function editSubmoduleRequest(payload) {
  return {
    type: types.EDIT_SUBMODULE_REQUEST,
    payload,
  };
}

export function editSubmoduleSuccess(payload) {
  return {
    type: types.EDIT_SUBMODULE_SUCCESS,
    payload,
  };
}
export function editSubmoduleFailure(payload) {
  return {
    type: types.EDIT_SUBMODULE_FAILURE,
    payload,
  };
}

export function editSectorRequest(payload) {
  return {
    type: types.EDIT_SECTOR_REQUEST,
    payload,
  };
}

export function editSectorSuccess(payload) {
  return {
    type: types.EDIT_SECTOR_SUCCESS,
    payload,
  };
}
export function editSectorFailure(payload) {
  return {
    type: types.EDIT_SECTOR_FAILURE,
    payload,
  };
}

export function editFunctionRequest(payload) {
  return {
    type: types.EDIT_FUNCTION_REQUEST,
    payload,
  };
}

export function editFunctionSuccess(payload) {
  return {
    type: types.EDIT_FUNCTION_SUCCESS,
    payload,
  };
}
export function editFunctionFailure(payload) {
  return {
    type: types.EDIT_FUNCTION_FAILURE,
    payload,
  };
}
export function registerSectorSuccess(payload) {
  return {
    type: types.REGISTER_SECTOR_SUCCESS,
    payload,
  };
}
export function registerSectorFailure(payload) {
  return {
    type: types.REGISTER_SECTOR_FAILURE,
    payload,
  };
}

export function registerSectorRequest(payload) {
  return {
    type: types.REGISTER_SECTOR_REQUEST,
    payload,
  };
}

export function registerFunctionSuccess(payload) {
  return {
    type: types.REGISTER_FUNCTION_SUCCESS,
    payload,
  };
}
export function registerFunctionFailure(payload) {
  return {
    type: types.REGISTER_FUNCTION_FAILURE,
    payload,
  };
}

export function registerFunctionRequest(payload) {
  return {
    type: types.REGISTER_FUNCTION_REQUEST,
    payload,
  };
}

export function editQuizRequest(payload) {
  return {
    type: types.EDIT_QUIZ_REQUEST,
    payload,
  };
}

export function editQuizSuccess(payload) {
  return {
    type: types.EDIT_QUIZ_SUCCESS,
    payload,
  };
}
export function editQuizFailure(payload) {
  return {
    type: types.EDIT_QUIZ_FAILURE,
    payload,
  };
}

export function editContentRequest(payload) {
  return {
    type: types.EDIT_CONTENT_REQUEST,
    payload,
  };
}

export function editContentSuccess(payload) {
  return {
    type: types.EDIT_CONTENT_SUCCESS,
    payload,
  };
}
export function editContentFailure(payload) {
  return {
    type: types.EDIT_CONTENT_FAILURE,
    payload,
  };
}

export function confirmRequest(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_REQUEST,
    payload,
  };
}

export function confirmAccountSuccess(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_SUCCESS,
    payload,
  };
}

export const resetRecaptcha = () => ({
  type: types.RESET_RECAPTCHA,
});

export const setRecaptchaReset = (value) => ({
  type: types.SET_RECAPTCHA_RESET,
  payload: value,
});

export function confirmAccountFailure(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_FAILURE,
    payload,
  };
}
export function registerRequest(payload) {
  return {
    type: types.REGISTER_REQUEST,
    payload,
  };
}

export function registerCreatedSuccess(payload) {
  return {
    type: types.REGISTER_CREATED_SUCCESS,
    payload,
  };
}

export function registerFailure(payload) {
  return {
    type: types.REGISTER_FAILURE,
    payload,
  };
}

export function registerEnterpriseRequest(payload) {
  return {
    type: types.REGISTER_ENTERPRISE_REQUEST,
    payload,
  };
}

export function registerEnterpriseCreatedSuccess(payload) {
  return {
    type: types.REGISTER_ENTERPRISE_CREATED_SUCCESS,
    payload,
  };
}

export function registerEnterpriseFailure(payload) {
  return {
    type: types.REGISTER_ENTERPRISE_FAILURE,
    payload,
  };
}
export function registerCourseRequest(payload) {
  return {
    type: types.REGISTER_COURSE_REQUEST,
    payload,
  };
}

export function registerCourseCreatedSuccess(payload) {
  return {
    type: types.REGISTER_COURSE_CREATED_SUCCESS,
    payload,
  };
}

export function registerCourseFailure(payload) {
  return {
    type: types.REGISTER_COURSE_FAILURE,
    payload,
  };
}

export function registerSubmoduleRequest(payload) {
  return {
    type: types.REGISTER_SUBMODULE_REQUEST,
    payload,
  };
}

export function registerSubmoduleCreatedSuccess(payload) {
  return {
    type: types.REGISTER_SUBMODULE_CREATED_SUCCESS,
    payload,
  };
}

export function registerSubmoduleFailure(payload) {
  return {
    type: types.REGISTER_SUBMODULE_FAILURE,
    payload,
  };
}

export function registerQuizRequest(payload) {
  return {
    type: types.REGISTER_QUIZ_REQUEST,
    payload,
  };
}

export function registerQuizCreatedSuccess(payload) {
  return {
    type: types.REGISTER_QUIZ_CREATED_SUCCESS,
    payload,
  };
}

export function registerQuizFailure(payload) {
  return {
    type: types.REGISTER_QUIZ_FAILURE,
    payload,
  };
}

export function registerContentRequest(payload) {
  return {
    type: types.REGISTER_CONTENT_REQUEST,
    payload,
  };
}

export function registerContentCreatedSuccess(payload) {
  return {
    type: types.REGISTER_CONTENT_CREATED_SUCCESS,
    payload,
  };
}

export function registerContentFailure(payload) {
  return {
    type: types.REGISTER_CONTENT_FAILURE,
    payload,
  };
}

export function passwordResetRequest(payload) {
  return {
    type: types.PASSWORD_RESET_REQUEST,
    payload,
  };
}

export function passwordResetSuccess() {
  return {
    type: types.PASSWORD_RESET_SUCCESS,
  };
}

export function passwordResetFailure() {
  return {
    type: types.PASSWORD_RESET_FAILURE,
  };
}

export function passwordRecoveryRequest(payload) {
  return {
    type: types.PASSWORD_RECOVERY_REQUEST,
    payload,
  };
}

export function passwordRecoverySuccess() {
  return {
    type: types.PASSWORD_RECOVERY_SUCCESS,
  };
}

export function passwordRecoveryFailure() {
  return {
    type: types.PASSWORD_RECOVERY_FAILURE,
  };
}

export function setDepartment(departamento) {
  return {
    type: types.SET_DEPARTMENT,
    payload: { departamento },
  };
}
