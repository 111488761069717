import React from 'react';
import { Container } from '../../styles/GlobalStyles';

export default function Certificaiton() {
  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100vh',
        paddingTop: '50px',
      }}
    >
      <iframe
        width="960"
        height="515"
        src="https://www.youtube.com/embed/55OdzWgwYQ8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Container>
  );
}
