import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete'; // Importação do Autocomplete adicionada
import PasswordChecklist from 'react-password-checklist';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from '@material-ui/core';
import axios from '../../services/axios';
import { Form, Bloco, StyledButton } from './styled';
import Loading from '../../components/Loading';

import * as actions from '../../store/modules/auth/actions';

export default function EditUser(props) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.auth.user.id);
  const nomeStored = useSelector((state) => state.auth.user.nome);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const sobrenomeStored = useSelector((state) => state.auth.user.sobrenome);
  const departamentoStored = useSelector((state) => state.auth.user.departamento);
  const emailStored = useSelector((state) => state.auth.user.email);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [setor, setSetor] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState(null);
  const [selectedFuncao, setSelectedFuncao] = useState(null);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSetorOpen, setIsSetorOpen] = useState(false); // Estado para controlar a abertura do menu de setor
  const [isFuncaoOpen, setIsFuncaoOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!id) return;

    setNome(nomeStored);
    setSobrenome(sobrenomeStored);
    setDepartamento(departamentoStored);
    setEmail(emailStored);
  }, [emailStored, departamentoStored, id, nomeStored, sobrenomeStored]);

  useEffect(() => {
    async function getData() {
      try {
        const responseSectors = await axios.get('/sectors');
        const empresaSectors = responseSectors.data.filter((sector) => sector.cnpjCreator === cnpj);
        setSetor(Array.isArray(empresaSectors) ? empresaSectors : []);

        const responseFunctions = await axios.get('/functions');
        const empresaFunctions = responseFunctions.data.filter((func) => func.cnpjCreator === cnpj);
        setFuncao(Array.isArray(empresaFunctions) ? empresaFunctions : []);
      } catch (error) {
        console.error('Failed to fetch sectors and functions', error);
      }
    }

    getData();
  }, [cnpj]);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/rhusers/${id}`);

        setSelectedSetor(data.setor);
        setSelectedFuncao(data.funcao);
      } catch (err) {
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);
        if (status === 400) errors.map((error) => toast.error(error));
        history.push('/home-empresa');
      }
    }

    getData();
  }, [id]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };
  const handleImageChange = () => {
    history.push('/profile-img');
    handleStop();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordAgain = () => {
    setShowPasswordAgain(!showPasswordAgain);
  };
  const handleMouseDownPasswordAgain = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordValidationChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  const handleSubmit = (e) => {
    const { handleClose } = props;
    e.preventDefault();

    let formErrors = false;

    if (!nome || nome.length < 3 || nome.length > 255) {
      formErrors = true;
      toast.error('Nome deve ter entre 3 e 255 caracteres');
    }

    if (!email || !isEmail(email)) {
      formErrors = true;
      toast.error('E-mail inválido.');
    }

    if (password && !isPasswordValid) {
      formErrors = true;
      toast.error('A senha não cumpre todos os requisitos');
    }

    if (formErrors) return;
    dispatch(
      actions.editRequest({
        nome,
        sobrenome,
        setor: selectedSetor,
        funcao: selectedFuncao,
        email,
        departamento,
        password,
        id,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Editar perfil</h1>
          <h4>Atualize suas informações de Perfil</h4>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            id="nome"
            label="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            placeholder="Nome"
          />

          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            id="sobrenome"
            label="Sobrenome"
            value={sobrenome}
            onChange={(e) => setSobrenome(e.target.value)}
            placeholder="Sobrenome"
          />

          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            id="departamento"
            label="Perfil"
            value={departamento}
            disabled
            onChange={(e) => setDepartamento(e.target.value)}
            placeholder="Departamento"
          />

          {departamentoStored === 'colaborador' && (
            <>
              <Autocomplete
                className="autocomplete"
                disablePortal
                id="combo-box-demo"
                open={isSetorOpen}
                options={setor.map((sector) => sector.sector)}
                getOptionLabel={(option) => option || ''}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  setSelectedSetor(newValue);
                }}
                value={selectedSetor}
                onOpen={() => setIsSetorOpen(true)} // Abre o menu quando o usuário interage
                onClose={() => setIsSetorOpen(false)}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    {...params}
                    label="Setor"
                    style={{ boxShadow: 'none', border: 'none' }}
                    inputProps={{ ...params.inputProps, style: { paddingLeft: 10 } }}
                  />
                )}
              />
              <Autocomplete
                className="autocomplete"
                disablePortal
                id="combo-box-demo"
                open={isFuncaoOpen}
                options={funcao.map((func) => func.function)}
                getOptionLabel={(option) => option || ''}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  setSelectedFuncao(newValue);
                }}
                value={selectedFuncao}
                onOpen={() => setIsFuncaoOpen(true)} // Abre o menu quando o usuário interage
                onClose={() => setIsFuncaoOpen(false)}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    {...params}
                    label="Função"
                    style={{ boxShadow: 'none' }}
                    inputProps={{ ...params.inputProps, style: { paddingLeft: 10 } }}
                  />
                )}
              />
            </>
          )}

          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            disabled
          />

          <TextField
            className="text-field password-field"
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            label="Password"
            placeholder="Password"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="button-eye"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className="text-field password-field"
            id="standard-adornment-password-again"
            type={showPasswordAgain ? 'text' : 'password'}
            value={passwordAgain}
            onChange={(e) => setPasswordAgain(e.target.value)}
            label="Password Again"
            placeholder="Password Again"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="button-eye"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordAgain}
                    onMouseDown={handleMouseDownPasswordAgain}
                  >
                    {showPasswordAgain ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <PasswordChecklist
            className="text-field"
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={passwordAgain}
            onChange={handlePasswordValidationChange}
            messages={{
              minLength: 'A senha deve ter no mínimo 8 caracteres.',
              specialChar: 'A senha deve conter pelo menos un caracteres especiais(Ex: !, @, #, etc.).',
              number: 'A senha deve conter pelo menos um número.',
              capital: 'A senha deve conter pelo menos uma letra maiúscula.',
              match: 'As senhas devem coincidir.',
            }}
          />
          <StyledButton disableRipple className="styled-button" type="submit" variant="contained" color="primary">
            Salvar
          </StyledButton>
          <StyledButton
            disableRipple
            className="styled-button"
            type="button"
            variant="contained"
            color="primary"
            onClick={handleImageChange}
          >
            Trocar Imagem de Perfil
          </StyledButton>
        </div>
      </Form>
    </Bloco>
  );
}

EditUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
