import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { Termos } from './styled';

function PdfViewer() {
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get('/contentsubmodule/71');
        console.log(response);
        setPdfData(response.data.documentUrl);
        setLoading(false);
      } catch (err) {
        setFetchError('Erro ao carregar o PDF');
        setLoading(false);
      }
    };

    fetchPdf();
  }, []);

  if (loading) {
    return <div>Carregando PDF...</div>;
  }

  if (fetchError) {
    return <div>{fetchError}</div>;
  }

  return (
    <Termos>
      <iframe src={pdfData} width="100%" height="600px" title="PDF Viewer" frameBorder="0" />
    </Termos>
  );
}

export default PdfViewer;
