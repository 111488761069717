import styled from 'styled-components';

export const CollaboratorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  margin: 0;
  padding: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  @media (min-width: 40px) {
    gap: 10px; /* Aumentar o espaçamento em telas maiores */
  }
  @media (min-width: 1024px) {
    gap: 20px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 10px;

  @media (min-width: 40px) {
    gap: 20px; /* Aumentar o espaçamento em telas maiores */
  }
  @media (min-width: 1024px) {
    gap: 20px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (max-width: 790px) {
    flex-direction: column;
  }
  .link {
    text-decoration: none;
    color: inherit;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px; /* Defina um tamanho fixo ou responsivo para o card */
  height: 160px;
  border-radius: 20px;
  background: white;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  margin: 10px;

  h1 {
    font-size: 1rem; /* 1.6 vezes o tamanho da fonte base */
  }

  .test {
    width: 4vw;
    height: 1%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;
    margin: 3%;

    @media (max-width: 1245px) {
      width: 40px;
      height: 40px;
    }
  }

  &:hover {
    transition: all 0.9s ease;
    transform: scale(1.05);
    color: #ae1100;
    box-shadow: 1px 1px 7px #ae1100, 1px 2px 8px #ae1100;
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1024px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1229px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1245px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 938px) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: 873px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 823px) {
    width: 70px;
    height: 70px;
  }
`;

export const NewUser = styled(Card)``;
export const AttUser = styled(Card)``;
export const Edit = styled(Card)``;
export const BlockUser = styled(Card)``;
export const EditUser = styled(Card)``;
