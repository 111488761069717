import { Button } from '@material-ui/core';
import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }

  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: 1% auto 0%;
  display: flex;
  margin: -2%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .invalid-label {
    color: red;
  }

  label {
    display: flex;
    margin-bottom: 10px;
    margin: 5px;
    color: #ae1100;
    margin-left: 15px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  h1 {
    margin: 10px 35%;
    margin-top: -20%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }

  h2 {
    margin: 10px 30%;
    margin-top: -20%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: -3%;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 15px 1px;
    font-size: 18px;
    margin-bottom: 2%;
  }

  .password-checklist {
    font-family: 'MyFont';
    border-color: #ae1100;
    label {
      font-family: 'MyFont';
      display: flex;
      border-color: #ae1100;
      font-size: 15px;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  input,
  select {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  @media (max-width: 768px) {
    input,
    select {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    input,
    select {
      width: 100%;
    }
  }
  .text-field {
    font-size: 15px;
    margin-bottom: -2%;
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .text {
    width: 507px;
    margin-bottom: -3%;
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }
  .logo {
    width: 350px;
    height: 350px;
    max-width: 550px; // Ajuste este valor conforme necessário
    max-height: 550px;
    margin: 0px 27px;
    padding: 1px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 482px) {
    margin-top: -30%;
  }
  .autocomplete {
    font-family: 'MyFont', sans-serif !important;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 3.2%;
      font-family: 'MyFont' !important;
      font-size: 15px; /* Adicionado tamanho da fonte */
    }
    .MuiAutocomplete-popupIndicator {
      font-family: 'MyFont', sans-serif !important;
      right: 65px; /* ajuste este valor conforme necessário */
    }
    .MuiInput-underline:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:after {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete .MuiInput-underline:before,
    .autocomplete .MuiInput-underline:after,
    .autocomplete .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete button,
    .autocomplete button:hover {
      font-family: 'MyFont', sans-serif !important;
    }

    .autocomplete .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }

    .autocomplete .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }

    .autocomplete input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    button {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    button:hover {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      max-height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }
    .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }
  }
  .terms-container {
    display: flex;
    align-items: center; /* Alinha o checkbox e o texto verticalmente */
    font-family: 'MyFont';
    font-size: 15px;
    margin-top: 30px;
    margin-left: 25%; /* Ajuste conforme a necessidade */
  }

  .terms-container a {
    color: #ae1100;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    margin-right: -80%;
  }

  .terms-container a:hover {
    text-decoration: underline;
  }

  .terms-container .MuiCheckbox-root {
    color: #ae1100;
    margin-top: -155%;
    margin-left: 400%;

    @media (max-width: 1575px) {
      margin-left: 500%;
      margin-top: -195%;
    }
    @media (max-width: 1085px) {
      margin-left: 530%;
      margin-top: -200%;
    }
  }

  .terms-container span {
    display: inline-flex;
    align-items: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    font-family: 'MyFont';
    margin-bottom: 20px;
    margin: 20px;
    margin-left: 15px;
  }

  button {
    margin-left: -1px;
    margin-top: 15%;
    width: 150px;
    color: white;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
  }

  input {
    width: 100%;
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: 'MyFont';
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .logo {
      width: 100%;
    }
  }

  a {
    margin-bottom: 20px;
    height: 40px;
    padding: 0 10px;
    border-radius: 4px;
  }
`;
export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff; /* Fundo branco */
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0; /* Mantém abaixo de Nav e SecondNav */

  .text-field {
    font-size: 15px;
    width: 450px;
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
`;
export const StyledButton = styled(Button)`
  margin: 50px -30px !important;
  margin-left: 50px !important;
  margin-top: 5px !important;
  width: 150px !important;
  color: white !important;
  font-weight: bold !important;
  background-color: #ae1100 !important;
  font-family: 'MyFont' !important;
  margin-bottom: 50% !important;
  text-transform: none !important;
  &:hover {
    background-color: #ae1100 !important;
    transition-duration: 0s !important;
  }
`;
