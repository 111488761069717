import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { isPast } from 'date-fns';
import { fromZonedTime, format as formatDate } from 'date-fns-tz';
import { Prompt } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // Importando ToastContainer
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import 'react-toastify/dist/ReactToastify.css'; // Importando o CSS

export default function Course({ match }) {
  const id = match?.params?.id || '';
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]); // Variável usada para armazenar os usuários
  const cnpj = useSelector((state) => state.auth?.user?.cnpj_enterprise); // CNPJ da empresa
  const [selectAll, setSelectAll] = useState(false);
  const [globalDate, setGlobalDate] = useState('');
  const [pendingChanges, setPendingChanges] = useState([]); // Estado para mudanças pendentes
  const [selectedSector, setSelectedSector] = useState('geral');
  // Função para buscar os dados de acesso do curso
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('/usercourse');
      const userCourses = data.filter((userCourse) => userCourse.courseId && userCourse.courseId === Number(id));

      const updatedAccess = await Promise.all(
        userCourses.map(async (userCourse) => {
          // Verificando se a data de expiração passou
          if (userCourse.accessExpirationDate && isPast(new Date(userCourse.accessExpirationDate))) {
            return { ...userCourse, hasAccess: false }; // Desativa o acesso
          }
          return userCourse;
        }),
      );

      setAccess(updatedAccess); // Atualiza o estado com os dados do acesso
    } catch (error) {
      console.error('Error fetching user courses:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectorChange = (event) => {
    setSelectedSector(event.target.value);
  };

  // Função para buscar os usuários e adicionar novos automaticamente
  const fetchUsersAndAddPermissions = async () => {
    setIsLoading(true);
    try {
      const { data: allUserCourses } = await axios.get('/usercourse'); // Obtém todos os registros de usercourse
      const { data: allUsers } = await axios.get('/rhusers');

      const empresaUsers = allUsers.filter(
        (user) =>
          user.departamento &&
          user.departamento.toLowerCase() !== 'empresa' &&
          user.departamento.toLowerCase() !== 'admin' &&
          user.cnpj_enterprise === cnpj,
      );

      setUsers(empresaUsers);

      // Filtrar novos usuários que ainda não estão no curso
      const newUsers = empresaUsers.filter(
        (user) =>
          !allUserCourses.some((userCourse) => userCourse.userId === user.id && userCourse.courseId === Number(id)),
      );

      // Adicionar novos usuários apenas se existirem
      if (newUsers.length > 0) {
        await Promise.all(
          newUsers.map((user) =>
            axios.post('/usercourse', {
              userId: user.id,
              courseId: Number(id),
              hasAccess: false,
              completionPercentage: 0,
            }),
          ),
        );
        fetchData(); // Atualiza os dados de acesso
      }
    } catch (error) {
      console.error('Error fetching RH users or adding permissions:', error);
      toast.error('Erro ao buscar usuários ou adicionar permissões.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUsersAndAddPermissions();
  }, [id, cnpj]);

  const geralUsers = users;

  const filteredUsers = selectedSector === 'geral' ? geralUsers : users.filter((user) => user.setor === selectedSector);

  // Função para buscar os usuários

  // Atualiza o estado de "Selecionar Todos" com base nos acessos
  useEffect(() => {
    const allSelected = users.every((user) => access.some((acesso) => acesso.userId === user.id && acesso.hasAccess));
    setSelectAll(allSelected);
  }, [access, users]);

  // Adiciona ou atualiza mudanças no estado pendente
  const updatePendingChanges = (userCourseId, changes) => {
    setPendingChanges((prev) => {
      const existingChange = prev.find((change) => change.userCourseId === userCourseId);
      if (existingChange) {
        // Atualiza apenas os campos específicos que mudaram
        return prev.map((change) => (change.userCourseId === userCourseId ? { ...change, ...changes } : change));
      }
      // Adiciona a alteração caso ainda não exista
      return [...prev, { userCourseId, ...changes }];
    });
  };

  // Atualiza a permissão do usuário localmente
  const handleToggleAccess = (userCourseId, hasAccess) => {
    updatePendingChanges(userCourseId, { hasAccess: !hasAccess });
  };

  // Altera a data de expiração do acesso do usuário localmente
  const handleDateChange = (userCourseId, newDate) => {
    const selectedDate = new Date(newDate);
    selectedDate.setDate(selectedDate.getDate() + 1); // Ajusta para o próximo dia
    updatePendingChanges(userCourseId, { accessExpirationDate: selectedDate.toISOString() });

    // Verificar se a nova data passou do limite e remover o acesso se necessário
    if (isPast(selectedDate)) {
      updatePendingChanges(userCourseId, { hasAccess: false }); // Remove o acesso
    }
  };

  // Marca ou desmarca todos os usuários
  const handleSelectAll = () => {
    const newSelectAllState = !selectAll;
    setSelectAll(newSelectAllState);

    setPendingChanges((prev) => {
      const updatedChanges = [...prev];
      users.forEach((user) => {
        const userAccess = access.find((acesso) => acesso.userId === user.id);
        if (userAccess) {
          const existingChange = updatedChanges.find((change) => change.userCourseId === userAccess.id);
          if (existingChange) {
            existingChange.hasAccess = newSelectAllState;
          } else {
            updatedChanges.push({ userCourseId: userAccess.id, hasAccess: newSelectAllState });
          }
        }
      });
      return updatedChanges;
    });
  };

  // Aplica uma data global para todos os usuários
  const handleGlobalDateChange = () => {
    if (!globalDate) return;

    const selectedDate = new Date(globalDate);
    selectedDate.setDate(selectedDate.getDate() + 1);
    const utcGlobalDate = selectedDate.toISOString();

    setPendingChanges((prev) => {
      const updatedChanges = [...prev];
      access.forEach((acesso) => {
        const existingChange = updatedChanges.find((change) => change.userCourseId === acesso.id);
        if (existingChange) {
          // Atualiza somente a data de expiração
          existingChange.accessExpirationDate = utcGlobalDate;
        } else {
          // Adiciona nova entrada para alterar somente a data
          updatedChanges.push({ userCourseId: acesso.id, accessExpirationDate: utcGlobalDate });
        }
      });
      return updatedChanges;
    });
  };

  // Salva todas as mudanças pendentes
  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      // Verifica se houve alteração no acesso devido à data expirada
      const expiredAccessChanges = pendingChanges.filter(
        (change) =>
          change.hasAccess === false && change.accessExpirationDate && isPast(new Date(change.accessExpirationDate)),
      );

      if (expiredAccessChanges.length > 0) {
        // Exibe o Toast avisando sobre os acessos revogados
        toast.warning('O acesso de alguns colaboradores foi revogado devido à expiração da data.');
      }

      // Aplica as alterações pendentes
      await Promise.all(
        pendingChanges.map((change) =>
          axios.put(`/usercourse/${change.userCourseId}`, {
            hasAccess: change.hasAccess,
            accessExpirationDate: change.accessExpirationDate,
          }),
        ),
      );

      setPendingChanges([]); // Limpa as alterações pendentes
      fetchData(); // Atualiza os dados do servidor
      toast.success('Alterações salvas com sucesso!'); // Exibe o Toast de sucesso
    } catch (error) {
      console.error('Error saving changes:', error);
      toast.error('Erro ao salvar as alterações. Tente novamente.'); // Exibe o Toast de erro
    } finally {
      setIsLoading(false);
    }
  };

  // Exibe a data formatada
  const handleDateDisplay = (date) => {
    if (date) {
      const utcDate = new Date(date);
      const localDate = fromZonedTime(utcDate, 'America/Sao_Paulo');
      return formatDate(localDate, 'yyyy-MM-dd');
    }
    return '';
  };

  const getDisplayValue = (userCourseId, field) => {
    const pendingChange = pendingChanges.find((change) => change.userCourseId === userCourseId);
    if (pendingChange && pendingChange[field] !== undefined) {
      return pendingChange[field];
    }
    const userAccess = access.find((acesso) => acesso.id === userCourseId);
    return userAccess ? userAccess[field] : null;
  };

  // Exibe a mensagem de alerta se houver alterações pendentes
  const handleBeforeUnload = (e) => {
    if (pendingChanges.length > 0) {
      const message = 'Você tem alterações não salvas. Deseja sair e perder as alterações?';
      e.returnValue = message; // Mensagem a ser exibida pelo navegador
      return message; // Retorna a mensagem para navegadores que não suportam a customização
    }
    return undefined; // Retorna undefined quando não há alterações pendentes
  };

  useEffect(() => {
    // Adiciona o evento de "beforeunload"
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o evento quando o componente for desmontado
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [pendingChanges]); // Dependência de pendingChanges

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1>Permissões</h1>
        <h4>Selecione colaborador para ter acesso ao curso</h4>

        {/* Prompt para interceptar mudanças de rota */}
        <Prompt
          when={pendingChanges.length > 0} // Exibe o alerta se houver mudanças pendentes
          message="Você tem alterações não salvas. Deseja sair e perder as alterações?"
        />

        <div>
          <p className="name-filter">Filtragem por setor</p>
          <select
            style={{ fontFamily: 'MyFont' }}
            id="sector-select"
            value={selectedSector}
            onChange={handleSectorChange}
          >
            <option style={{ fontFamily: 'MyFont' }} value="geral">
              Geral
            </option>
            {users
              .map((user) => user.setor)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((sector) => (
                <option style={{ fontFamily: 'MyFont' }} key={sector} value={sector}>
                  {sector}
                </option>
              ))}
          </select>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontFamily: 'MyFont' }}>
                <input
                  style={{ fontFamily: 'MyFont' }}
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Selecionar Todos
              </TableCell>
              <TableCell style={{ fontFamily: 'MyFont' }}>Data de Expiração do Acesso</TableCell>
              <TableCell style={{ fontFamily: 'MyFont' }}>Tem Acesso?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => {
              const userAccess = access.find((acesso) => acesso.userId === user.id) || {};
              const displayAccess = getDisplayValue(userAccess.id, 'hasAccess');
              const displayDate = getDisplayValue(userAccess.id, 'accessExpirationDate');

              return (
                <TableRow key={user.id}>
                  <TableCell>
                    {user.nome} {user.sobrenome}
                  </TableCell>
                  <TableCell>
                    <input
                      type="date"
                      value={handleDateDisplay(displayDate)}
                      onChange={(e) => handleDateChange(userAccess.id, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={displayAccess}
                      onChange={() => handleToggleAccess(userAccess.id, displayAccess)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <h5>Aplicar Mesma Data para Todos</h5>
        <div>
          <input type="date" value={globalDate} onChange={(e) => setGlobalDate(e.target.value)} />
          <button type="button" onClick={handleGlobalDateChange}>
            Aplicar Data para Todos
          </button>
        </div>

        <div>
          <button className="button" type="button" onClick={handleSaveChanges} disabled={pendingChanges.length === 0}>
            Salvar Alterações
          </button>
        </div>
      </CollaboratorContainer>

      {/* Adicionando o ToastContainer */}
      <ToastContainer />
    </Container>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
