import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FaEdit, FaUser } from 'react-icons/fa'; // Importando o ícone de usuário
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';

import Loading from '../../components/Loading';

export default function User() {
  const [rhusers, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/empresa');
        const empresaUsers = response.data;
        setUsers(empresaUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <CollaboratorContainer>
        <h1>Ajuste de Empresa</h1>
        <h4>Atualize informações de empresa</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Usuários</TableCell> {/* Coluna adicionada */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rhusers.map((empresa) => (
              <TableRow key={String(empresa.id)}>
                <TableCell>{empresa.nome}</TableCell>
                <TableCell>{empresa.email}</TableCell>
                <TableCell>
                  <Link to={`/adm/${empresa.id}/edit`}>
                    <Button type="button" className="no-hover">
                      <FaEdit color="black" size={isMobile ? 10 : 16} style={{ marginLeft: '-5%' }} />
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/adm/${empresa.id}/users`}>
                    {' '}
                    {/* Ícone com Link */}
                    <FaUser size={isMobile ? 10 : 16} color="black" style={{ marginLeft: '27%' }} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CollaboratorContainer>
    </Container>
  );
}
