import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 80%;
  max-width: 900px;
  padding: 10px 10px;
  border-radius: 8px;
  margin: 0px 0px;

  h1 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 1%;
  }

  h4 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 2%;
  }

  p {
    font-family: 'MyFont';
    color: black;
    text-decoration: underline;
  }

  margin-top: 4%;
  margin: 50px;

  div {
    display: flex;
    justify-content: space-between;
    padding: 5px 100px;
    margin-left: -20%;
  }

  div + div {
    border-top: 1px solid #eee;
    padding: 10px 100px;
    margin-left: -30%;
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }
  }

  .no-hover {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
`;

export const NewCollaborator = styled(Link)`
  display: block;
`;

export const CenteredText = styled.h1`
  margin-top: 5%;
  margin-bottom: 50px;
`;
