import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  font-family: 'MyFont';
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Overlay = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
  z-index: 999; /* Garanta que o overlay fique abaixo do modal */
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  .form-error {
    border: 1px solid red;
  }
  .Textarea {
    width: 80;
  }
  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 5px 0px;
    font-size: 18px;
    font-family: 'MyFont';
  }

  p {
    margin: 5px 0px;
    font-size: 15px;
    font-family: 'MyFont';
  }

  .texto {
    font-size: 15px;
    font-family: 'MyFont';
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    z-index: 1000;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin: 5px 5px;
    font-size: 15px;
    font-family: 'MyFont';
  }

  Button {
    margin-left: 0px;
    margin: 0px 5px;
    margin-top: 5px;
    width: 150px;
    height: 40px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100 !important;
    color: #fff !important;
    border: none !important;
  }
  .button-custom:hover {
    background-color: #ae1100 !important; /* Ou qualquer cor que você queira para o hover */
  }
  .button-custom:disabled {
    background-color: #ae1100 !important; /* Cor cinza para desabilitado */
    color: #ae1100 !important; /* Cor do texto quando desabilitado */
  }

  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
    .button-custom {
      background-color: #ae1100 !important;
      color: #ae1100 !important;
      border: none !important;
    }

    .button-custom:hover {
      background-color: #ae1100 !important; /* Ou qualquer cor que você queira para o hover */
    }
  }
  @media (max-width: 768px) {
    input,
    select {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    input,
    select {
      width: 100%;
    }
  }
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
  }
  .form-control {
    margin: 50px 0px;
    width: 500px;
    margin-right: 90%;
    & label.Mui-focused {
      color: #ae1100;
    }
  }
  .form-control h1 {
    margin-right: 80%; /* Ajusta a margem direita */
  }
  .box-container {
    border: 2px solid #ddd; /* Adiciona a borda preta */
    padding: 20px; /* Adiciona preenchimento interno */
    margin-top: 90px; /* Adiciona margem superior */
    border-radius: 8px; /* Adiciona cantos arredondados */
    margin-bottom: 10px; /* Adiciona margem inferior */
    position: relative; /* Define posicionamento relativo */
  }

  .box-container h1 {
    position: relative; /* Define posicionamento relativo para o h1 */
    padding: 0px 5px;
    z-index: 1; /* Define a ordem de empilhamento para ficar acima da borda */
    margin-right: 60%; /* Ajusta a margem direita */
    background-color: white;
  }
`;

export const InputWrapper = styled.div`
  .Textarea {
    width: 80;
  }
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  input {
    flex: 1;
    margin-right: 10px;
    z-index: 1000;
    font-family: 'MyFont';

    &:nth-child(3n) {
      margin-right: 0; /* Remove a margem direita do terceiro input em cada linha */
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    height: 20px;
    margin: -3% 0%;
    position: relative;
    width: 30px;
    font-family: 'MyFont';
    cursor: default;

    .texto input[type='checkbox'] {
      width: 50px;
      margin-left: 40%; /* Define o estilo específico para este input */
    }
    &::before {
      bottom: -6px;
      content: '';
      left: -8px;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: #ae1100;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #ae1100;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }

  @media (max-width: 768px) {
    input,
    select {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    input,
    select {
      width: 100%;
    }
  }
`;
