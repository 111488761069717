import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import Tamb from '../../assets/img/tambPodCast.svg';
import Quarteto from '../../assets/img/quartetoFantastico.svg';

const mediaItems = [
  {
    id: 1,
    title: 'Startup de professor da Unit-PE é uma das maiores do Brasil',
    description: 'A empresa de cibersegurança é a única pernambucana no top 30 nacional',
    imageUrl: Quarteto,
    link: 'https://pe.unit.br/blog/noticias/startup-de-professor-da-unit-pe-e-uma-das-maiores-do-brasil/',
  },
  {
    id: 2,
    title: 'BlackBelt IT Solutions e Segurança da Informação',
    description:
      'Somos Faixa Preta em Segurança da Informação, Privacidade e Governança de Dados (LGPD). Quer entender mais? Assista o podcast!',
    imageUrl: Tamb,
    link: 'https://www.youtube.com/watch?v=dJ5U78EoEvw&t=2s',
  },
];

function MediaPage() {
  return (
    <Box
      id="midia"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 5, sm: 7 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        style={{ fontFamily: 'MyFont', fontWeight: 'bold' }}
        sx={{
          fontFamily: 'MyFont',
          fontSize: { xs: '24px', sm: '28px', md: '35px' },
          fontWeight: 'bold',
          textAlign: { xs: 'center' },
        }}
      >
        Mídia
      </Typography>
      <Grid container spacing={3} justifyContent="center" style={{ padding: '50px 40px' }}>
        {mediaItems.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', fontFamily: 'MyFont' }}
            >
              <Card style={{ height: '310px', margin: '20px' }}>
                <CardMedia
                  component="img"
                  height="120"
                  image={item.imageUrl}
                  alt={item.title}
                  style={{ objectFit: 'cover' }}
                />
                <CardContent
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '200px' }}
                >
                  <Typography variant="h5" component="div" style={{ fontFamily: 'MyFont' }}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ fontFamily: 'MyFont', flexGrow: 1, marginTop: '5%' }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default MediaPage;
