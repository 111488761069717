import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Background from '../../assets/img/background.png';

import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, StyledCard } from './styled';
import axios from '../../services/axios';

import Loading from '../../components/Loading';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    color: '#000',
    justifyContent: 'flex-start',
    padding: 50,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    padding: 12,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 100,
  },
  footer: {
    fontSize: 20,
    marginTop: 25,
    textAlign: 'center',
    paddingTop: 10,
    fontWeight: 1000,
  },
  text: {
    fontSize: 14,
    marginTop: 80,
  },
  text2: {
    fontSize: 14,
    marginTop: 40,
    marginBottom: 45,
  },
  textdata: {
    marginTop: 35,
    marginBottom: 45,
    textAlign: 'center',
  },
});
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 700,
  height: '90vh',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '16px',
  overflow: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};
function MyDocument({ name, sobrenome, empresa, data, coursename }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.backgroundContainer}>
          <Image style={styles.backgroundImage} src={Background} />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>Certificado de Conclusão</Text>
          <Text style={styles.text}>
            Certificamos que {name.toUpperCase()} {sobrenome.toUpperCase()} concluiu com sucesso a capacitação em{' '}
            {coursename}.
          </Text>
          <Text style={styles.text2}>
            Este certificado é concedido em reconhecimento ao seu compromisso e compreensão dos princípios e práticas
            essenciais para garantir a proteção de informações e dados da {empresa && empresa.nome}.
          </Text>
          <Text style={styles.textdata}>
            Data de Conclusão: <Text style={styles.text}>{data}</Text>
          </Text>
          <Text style={styles.footer}>BlackBelt CyberSensei</Text>
        </View>
      </Page>
    </Document>
  );
}

export default function Certificaiton() {
  const id = useSelector((state) => state.auth.user.id);
  const name = useSelector((state) => state.auth.user.nome);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const sobrenome = useSelector((state) => state.auth.user.sobrenome);
  const [courses, setCourse] = useState([]);
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const data = new Date().toLocaleDateString('pt-BR');
  const [empresa, setEmpresa] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [answered, setAnswered] = useState(false);
  console.log(answered);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const filteredCourses = courses.filter((course) =>
    access.some((ac) => ac.courseId === course.id && ac.userId === id && ac.completionPercentage === 100),
  );
  useEffect(() => {
    async function getCourses() {
      setIsLoading(true);
      try {
        const response = await axios.get('/course');
        const course = response.data;
        setCourse(course);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getCourses();
  }, []);

  useEffect(() => {
    async function getUserData() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/rhusers/${id}`);
        setAnswered(response.data.answered || false);
        setFormSubmitted(response.data.answered || false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    getUserData();
  }, [id]);

  useEffect(() => {
    async function getCourses() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/rhusers/${id}`);
        const users = response.data;
        console.log(users);
        setFilteredUser(users);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getCourses();
  }, []);

  const handleClick = async () => {
    if (!answered) {
      setIsModalOpen(true);
      setIsFormOpen(true);
      return;
    }

    try {
      const userData = { answered: true };
      await axios.put(`/rhusers/${id}`, userData);
      setAnswered(true);
      setFormSubmitted(true);
      setIsFormOpen(false);
    } catch (err) {
      console.error('Error:', err);
      toast.error('Erro ao enviar o formulário.');
    }
  };

  useEffect(() => {
    async function getUsers() {
      setIsLoading(true);
      try {
        const responseEmpresa = await axios.get(`/empresa`);
        console.log(responseEmpresa);
        const filterEmp = responseEmpresa.data.filter((emp) => emp.cnpj === cnpj);
        console.log(filterEmp);
        const empresas = filterEmp[0];
        setEmpresa(empresas);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getUsers();
  }, []);

  useEffect(() => {
    async function getAccess() {
      setIsLoading(true);
      try {
        const response = await axios.get('/usercourse');
        const courseAccess = response.data.filter(
          (UserCourse) =>
            UserCourse.userId && UserCourse.userId === Number(id) && UserCourse.completionPercentage === 100,
        );
        setAccess(courseAccess);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getAccess();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsFormOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsFormOpen(false);
  };

  const handleFormSubmit = async () => {
    try {
      const userData = { answered: true };
      const response = await axios.put(`/rhusers/${id}`, userData);

      if (response.status === 200) {
        setFormSubmitted(true);
        toast.success('Formulário enviado com sucesso!');
        window.location.reload(); // Recarrega a página após o update
      } else {
        toast.error('Erro ao enviar o formulário: resposta inesperada.');
      }
    } catch (err) {
      console.error('Erro ao enviar o formulário:', err);
      toast.error(`Erro ao enviar o formulário: ${err.message || 'Erro desconhecido.'}`);
    }
  };
  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        {isLoading && <p>Carregando...</p>}
        {!isLoading && filteredCourses.length > 0 && (
          <Grid container spacing={1}>
            {filteredCourses.map((course) => {
              const courseAccess = access.find((ac) => ac.courseId === course.id);
              return (
                <Grid xs={12} sm={6} md={4} key={String(course.id)}>
                  <StyledCard>
                    <CardMedia component="img" alt={course.name} height="110" image={course.img} className="img" />
                    <CardContent>
                      <Typography
                        variant="h1"
                        className="h1"
                        component="div"
                        align="left"
                        style={{ fontFamily: 'MyFont' }}
                      >
                        <h1>{course.name}</h1>
                      </Typography>
                      <div>{course.description}</div>
                    </CardContent>
                    <CardActions>
                      {formSubmitted ? (
                        <PDFDownloadLink
                          document={
                            <MyDocument
                              name={name}
                              sobrenome={sobrenome}
                              coursename={course.name}
                              empresa={empresa}
                              data={courseAccess ? new Date(courseAccess.updated_at).toLocaleDateString('pt-BR') : data}
                            />
                          }
                          fileName="certificado.pdf"
                          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', width: '100%' }}
                        >
                          {({ loading }) => (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                fontFamily: 'MyFont',
                                backgroundColor: '#ae1100',
                                padding: '9px 24px',
                                fontSize: '15px',
                                fontWeight: 'bold',
                                width: '100%',
                                textTransform: 'none',
                                color: 'white',
                              }}
                            >
                              {loading ? 'Carregando certificado...' : 'Baixar Certificado'}
                            </Button>
                          )}
                        </PDFDownloadLink>
                      ) : (
                        <Button
                          onClick={handleClick}
                          style={{
                            fontFamily: 'MyFont',
                            backgroundColor: '#ae1100',
                            padding: '12px 24px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                            width: '100%',
                            textTransform: 'none',
                            color: 'white',
                          }}
                        >
                          Baixar Certificado
                        </Button>
                      )}
                    </CardActions>
                  </StyledCard>
                </Grid>
              );
            })}
          </Grid>
        )}
        {!isLoading && filteredCourses.length === 0 && <p>Você ainda não tem certificados disponiveis.</p>}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={modalStyle}>
            <IconButton onClick={handleCloseModal} sx={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>

            {!isFormOpen ? (
              <>
                <Typography
                  style={{ fontFamily: 'MyFont', padding: '40px', textAlign: 'center', marginTop: '30%' }}
                  variant="h6"
                  component="h2"
                >
                  Para baixar seu certificado, preencha o formulário de avaliação abaixo. Após o preenchimento, clique
                  em &quot;Enviar&quot; e, em seguida, selecione o botão &quot;Baixar Certificado&quot;.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Button
                    style={{
                      fontFamily: 'MyFont',
                      backgroundColor: '#ae1100',
                      textTransform: 'none',
                      color: 'white',
                      padding: '5px 50px',
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                    onClick={handleOpenModal}
                    sx={{ marginTop: 2 }}
                  >
                    Abrir Form
                  </Button>
                </Box>
              </>
            ) : (
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSe6l79AJH7dLndHk44Hx0yksKYOHa3xwn5iykTsTQ79cJE93Q/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="Formulário de Certificado"
              >
                Carregando…
              </iframe>
            )}

            {isFormOpen && (
              <Button
                style={{
                  fontFamily: 'MyFont',
                  backgroundColor: '#ae1100',
                  textTransform: 'none',
                  color: 'white',
                  padding: '5px 10px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
                onClick={handleFormSubmit}
                sx={{ marginTop: 2 }}
              >
                Baixar Certificado
              </Button>
            )}
          </Box>
        </Modal>
      </CollaboratorContainer>
    </Container>
  );
}

MyDocument.propTypes = {
  name: PropTypes.string.isRequired,
  sobrenome: PropTypes.string.isRequired,
  coursename: PropTypes.string.isRequired,
  empresa: PropTypes.shape({
    nome: PropTypes.string,
  }).isRequired,
  data: PropTypes.string.isRequired,
};
