import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importando o ícone do WhatsApp
import Logo from '../../assets/img/CyberSensei.svg'; // Importe sua logo aqui

function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                src={Logo}
                alt="Logo da sua empresa"
                style={{ width: '140px', height: 'auto', cursor: 'pointer' }}
                className="logo"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem onClick={() => scrollToSection('video')} sx={{ py: '6px', px: '12px' }}>
                  <Typography style={{ fontFamily: 'MyFont', color: 'black' }} variant="body2" color="text.primary">
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'MyFont', color: 'black' }}
                  onClick={() => scrollToSection('features')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography
                    id="QuemSomos"
                    style={{ fontFamily: 'MyFont', color: 'black' }}
                    variant="body2"
                    color="text.primary"
                  >
                    Quem Somos
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="Soluções"
                  style={{ fontFamily: 'MyFont', color: 'black' }}
                  onClick={() => scrollToSection('highlights')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography style={{ fontFamily: 'MyFont', color: 'black' }} variant="body2" color="text.primary">
                    Soluções
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('faq')} sx={{ py: '6px', px: '12px' }}>
                  <Typography
                    id="PerguntasFrequentes"
                    style={{ fontFamily: 'MyFont', color: 'black' }}
                    variant="body2"
                    color="text.primary"
                  >
                    Perguntas Frequentes
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="Depoimentos"
                  onClick={() => scrollToSection('testimonials')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography style={{ fontFamily: 'MyFont', color: 'black' }} variant="body2" color="text.primary">
                    Depoimentos
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('midia')} sx={{ py: '6px', px: '12px' }}>
                  <Typography style={{ fontFamily: 'MyFont', color: 'black' }} variant="body2" color="text.primary">
                    Mídia
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
                fontFamily: 'MyFont',
                color: 'white',
              }}
            >
              <Link to="/login" target="_blank" rel="noopener noreferrer">
                <Button
                  style={{ fontFamily: 'MyFont', color: 'black' }}
                  color="primary"
                  variant="text"
                  size="small"
                  sx={{
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    fontSize: { xs: '16px', sm: '14px' }, // Tamanho da fonte responsivo
                    '&:hover': {
                      color: '#4C5967',
                    },
                  }}
                >
                  Login
                </Button>
              </Link>
              <Button
                id="Whatsapp"
                style={{ fontFamily: 'MyFont', color: 'white' }}
                color="primary"
                variant="contained"
                component="a"
                href="https://api.whatsapp.com/send/?phone=5581996527732&text&type=phone_number&app_absent=0"
                target="_blank"
                startIcon={<WhatsAppIcon sx={{ width: '22px', height: '22px', marginLeft: '10%' }} />}
                sx={{
                  width: 'auto',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  fontSize: { xs: '16px', sm: '14px' }, // Tamanho da fonte responsivo
                  '&:hover': {
                    backgroundColor: '#8b0e00',
                    color: 'white',
                  },
                }}
              />
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Button
                style={{ fontFamily: 'MyFont', color: 'black' }}
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={() => setOpen(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer
                style={{ fontFamily: 'MyFont', color: 'black' }}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
              >
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <MenuItem style={{ fontFamily: 'MyFont', color: 'black' }} onClick={() => scrollToSection('video')}>
                      Home
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: 'MyFont', color: 'black' }}
                      onClick={() => scrollToSection('features')}
                    >
                      Quem Somos
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: 'MyFont', color: 'black' }}
                      onClick={() => scrollToSection('highlights')}
                    >
                      Soluções
                    </MenuItem>
                    <MenuItem style={{ fontFamily: 'MyFont', color: 'black' }} onClick={() => scrollToSection('faq')}>
                      FAQ
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: 'MyFont', color: 'black' }}
                      onClick={() => scrollToSection('testimonials')}
                    >
                      Depoimentos
                    </MenuItem>
                    <MenuItem style={{ fontFamily: 'MyFont', color: 'black' }} onClick={() => scrollToSection('midia')}>
                      Mídia
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <Button
                        style={{ fontFamily: 'MyFont', color: 'white', backgroundColor: '#ae1100' }}
                        color="primary"
                        variant="text"
                        size="small"
                        component="a"
                        href="https://cybersensei.com.br/login"
                        target="_blank"
                        sx={{
                          transition: 'background-color 0.3s ease, color 0.3s ease',
                          fontSize: { xs: '16px', sm: '14px' }, // Tamanho da fonte responsivo
                          '&:hover': {
                            backgroundColor: '#8b0e00',
                            color: 'white',
                          },
                        }}
                      >
                        Login
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        style={{ fontFamily: 'MyFont', color: 'white' }}
                        color="primary"
                        variant="contained"
                        component="a"
                        href="https://cybersensei.com.br/login"
                        target="_blank"
                        startIcon={<WhatsAppIcon sx={{ width: '22px', height: '22px', marginLeft: '10%' }} />}
                        sx={{
                          width: 'auto',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'background-color 0.3s ease, color 0.3s ease',
                          fontSize: { xs: '16px', sm: '14px' }, // Tamanho da fonte responsivo
                          '&:hover': {
                            backgroundColor: '#8b0e00',
                            color: 'white',
                          },
                        }}
                      />
                    </MenuItem>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
