export const BOTAO_CLICADO_SUCCESS = 'BOTAO_CLICADO_SUCCESS';
export const BOTAO_CLICADO_REQUEST = 'BOTAO_CLICADO_REQUEST';
export const BOTAO_CLICADO_FAILURE = 'BOTAO_CLICADO_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAILURE = 'EDIT_FAILURE';

export const EDIT_OTHER_USER_REQUEST = 'EDIT_OTHER_USER_REQUEST';
export const EDIT_OTHER_USER_SUCCESS = 'EDIT_OTHER_USER_SUCCESS';
export const EDIT_OTHER_USER_FAILURE = 'EDIT_OTHER_USER_FAILURE';

export const EDIT_COURSE_REQUEST = 'EDIT_COURSE_REQUEST';
export const EDIT_COURSE_SUCCESS = 'EDIT_COURSE_SUCCESS';
export const EDIT_COURSE_FAILURE = 'EDIT_COURSE_FAILURE';

export const RESET_RECAPTCHA = 'RESET_RECAPTCHA';
export const SET_RECAPTCHA_RESET = 'SET_RECAPTCHA_RESET';

export const EDIT_CONTENT_REQUEST = 'EDIT_CONTENT_REQUEST';
export const EDIT_CONTENT_SUCCESS = 'EDIT_CONTENT_SUCCESS';
export const EDIT_CONTENT_FAILURE = 'EDIT_CONTENT_FAILURE';

export const EDIT_SUBMODULE_REQUEST = 'EDIT_SUBMODULE_REQUEST';
export const EDIT_SUBMODULE_SUCCESS = 'EDIT_SUBMODULE_SUCCESS';
export const EDIT_SUBMODULE_FAILURE = 'EDIT_SUBMODULE_FAILURE';

export const EDIT_SECTOR_REQUEST = 'EDIT_SECTOR_REQUEST';
export const EDIT_SECTOR_SUCCESS = 'EDIT_SECTOR_SUCCESS';
export const EDIT_SECTOR_FAILURE = 'EDIT_SECTOR_FAILURE';

export const EDIT_FUNCTION_REQUEST = 'EDIT_FUNCTION_REQUEST';
export const EDIT_FUNCTION_SUCCESS = 'EDIT_FUNCTION_SUCCESS';
export const EDIT_FUNCTION_FAILURE = 'EDIT_FUNCTION_FAILURE';

export const REGISTER_SECTOR_REQUEST = 'REGISTER_SECTOR_REQUEST';
export const REGISTER_SECTOR_SUCCESS = 'REGISTER_SECTOR_SUCCESS';
export const REGISTER_SECTOR_FAILURE = 'REGISTER_SECTOR_FAILURE';

export const REGISTER_FUNCTION_REQUEST = 'REGISTER_FUNCTION_REQUEST';
export const REGISTER_FUNCTION_SUCCESS = 'REGISTER_FUNCTION_SUCCESS';
export const REGISTER_FUNCTION_FAILURE = 'REGISTER_FUNCTION_FAILURE';

export const EDIT_QUIZ_REQUEST = 'EDIT_QUIZ_REQUEST';
export const EDIT_QUIZ_SUCCESS = 'EDIT_QUIZ_SUCCESS';
export const EDIT_QUIZ_FAILURE = 'EDIT_QUIZ_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const REGISTER_ENTERPRISE_REQUEST = 'REGISTER_ENTERPRISE_REQUEST';
export const REGISTER_ENTERPRISE_FAILURE = 'REGISTER_ENTERPRISE_FAILURE';
export const REGISTER_ENTERPRISE_CREATED_SUCCESS = 'REGISTER_ENTERPRISE_SUCCESS';

export const REGISTER_UPDATED_SUCCESS = 'REGISTER_UPDATED_SUCCESS';
export const REGISTER_CREATED_SUCCESS = 'REGISTER_CREATED_SUCCESS';

export const PERSIST_REHYDRATE = 'persist/REHYDRATE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const PASSWORD_RECOVERY_REQUEST = 'PASSWORD_RECOVERY_REQUEST';
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS';
export const PASSWORD_RECOVERY_FAILURE = 'PASSWORD_RECOVERY_FAILURE';

export const CONFIRM_ACCOUNT_REQUEST = 'CONFIRM_ACCOUNT_REQUEST';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILURE = 'CONFIRM_ACCOUNT_FAILURE';

export const REGISTER_COURSE_REQUEST = 'REGISTER_COURSE_REQUEST';
export const REGISTER_COURSE_CREATED_SUCCESS = 'REGISTER_COURSE_CREATED_SUCCESS';
export const REGISTER_COURSE_FAILURE = 'REGISTER_COURSE_FAILURE';

export const REGISTER_SUBMODULE_REQUEST = 'REGISTER_SUBMODULE_REQUEST';
export const REGISTER_SUBMODULE_CREATED_SUCCESS = 'REGISTER_SUBMODULE_CREATED_SUCCESS';
export const REGISTER_SUBMODULE_FAILURE = 'REGISTER_SUBMODULE_FAILURE';

export const REGISTER_CONTENT_REQUEST = 'REGISTER_CONTENT_REQUEST';
export const REGISTER_CONTENT_CREATED_SUCCESS = 'REGISTER_CONTENT_CREATED_SUCCESS';
export const REGISTER_CONTENT_FAILURE = 'REGISTER_CONTENT_FAILURE';

export const REGISTER_QUIZ_REQUEST = 'REGISTER_QUIZ_REQUEST';
export const REGISTER_QUIZ_CREATED_SUCCESS = 'REGISTER_QUIZ_CREATED_SUCCESS';
export const REGISTER_QUIZ_FAILURE = 'REGISTER_QUIZ_FAILURE';

export const SET_DEPARTMENT = 'auth/SET_DEPARTMENT';
export const EDIT_FIELD = 'EDIT_FIELD';
