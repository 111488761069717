import styled from 'styled-components';

import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  font-family: 'MyFont';
  width: 85vh;
  height: 40vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'MyFont';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: 2% auto 0%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h1 {
    margin: 32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;

    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  h4 {
    margin: 5px 4px;
    font-size: 18px;
    margin-bottom: -2%;
    font-family: 'MyFont';
  }
  .button {
    margin-left: -2px;
    margin: 0px 10px;
    font-family: 'MyFont', sans-serif !important;
    margin-top: 20px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }
  .button-custom {
    font-family: 'MyFont', sans-serif !important;
    background-color: #ae1100 !important;
    color: #fff !important;
    border: none !important;
  }
  .button-custom:hover {
    font-family: 'MyFont', sans-serif !important;
    background-color: #ae1100 !important; /* Ou qualquer cor que você queira para o hover */
  }
  .button-custom:disabled {
    font-family: 'MyFont', sans-serif !important;
    background-color: #ae1100 !important; /* Cor cinza para desabilitado */
    color: #ae1100 !important; /* Cor do texto quando desabilitado */
  }
  label {
    font-size: 15px; /* Adicionado tamanho da fonte */
    font-family: 'MyFont';
  }
`;

export const Form = styled.form`
  margin-top: -20px;
  font-family: 'MyFont', sans-serif !important;
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  position: relative;
  label textarea[type='text'] {
    margin-left: -10px;
    width: 480px; /* Define a largura do input como 100% */
    height: 100px;
    font-family: 'MyFont';
    font-size: 15px;
    font-family: 'MyFont';
    border: 1px solid #ddd;
    padding: 0 5px;
    margin: -55px 0px 0px -10px; /* Ajustado para ocupar menos espaço vertical */
    border-radius: 4px;

    &:focus {
      border: 1px solid red;
    }
  }
  .autocomplete {
    width: 100%;
    margin: 10px 0;
    font-family: 'MyFont', sans-serif;
    & > div {
      width: 100%;
    }
  }
  .autocomplete {
    font-family: 'MyFont', sans-serif !important;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 3.2%;
      font-family: 'MyFont' !important;
      font-size: 15px; /* Adicionado tamanho da fonte */
    }
    .MuiAutocomplete-popupIndicator {
      position: absolute;
      font-family: 'MyFont', sans-serif !important;
      right: 65px; /* ajuste este valor conforme necessário */
    }
    .MuiInput-underline:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:after {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete .MuiInput-underline:before,
    .autocomplete .MuiInput-underline:after,
    .autocomplete .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete button,
    .autocomplete button:hover {
      font-family: 'MyFont', sans-serif !important;
    }

    .autocomplete .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }

    .autocomplete .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }

    .autocomplete input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    button {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    button:hover {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      max-height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }
    .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }
  }

  h1 {
    margin: -35px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 5px 5px;
    font-size: 18px;
    margin-bottom: -2%;
    font-family: 'MyFont';
  }
  h2 {
    margin: 30px 35px;
    font-size: 18px;
    font-family: 'MyFont';
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin: 5px;
    margin-left: 15px;
    font-family: 'MyFont';
  }

  button {
    margin-top: 10px;
    width: 150px;
    color: white;
    margin: 1%;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }

  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0px 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 50px -5px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 0px;
    font-size: 15px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ea1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }

  .text-field {
    font-size: 15px;
    font-family: 'MyFont';
    & label.Mui-focused {
      color: #ae1100;
      font-size: 15px;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        font-family: 'MyFont';
        border-color: #ae1100;
        font-size: 15px;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    font-family: 'MyFont', sans-serif !important;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 65%;
    background-color: white;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 15px;
    font-family: 'MyFont';
    font-size: 15px; /* Adicionado tamanho da fonte */
  }
`;

export const CenteredText = styled.h1`
  margin-bottom: 10px;
  font-family: 'MyFont', sans-serif !important;
  margin: 2px 60px -5px 0px; /* Ajustado para ocupar menos espaço vertical */
  text-transform: none;
`;
