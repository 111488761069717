import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Logo from '../../assets/img/telaDeLoginCyber.svg';
import Selo from '../../assets/img/SELO (2).svg';

const logoStyle = {
  width: '80%',
  height: 'auto',
  cursor: 'pointer',
  margin: '-80px auto',
};

const seloStyle = {
  width: '50%',
  height: 'auto',
  cursor: 'pointer',
  margin: '0 15%',
};

function Features() {
  return (
    <Container id="features" sx={{ py: { xs: 7, sm: 10 } }}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <a
              href="https://programas.sebraestartups.com.br/in/premiosebraestartups"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Selo} alt="Selo Sebrae" style={seloStyle} />
            </a>
            <img src={Logo} alt="Logo da sua empresa" style={logoStyle} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              component="h2"
              variant="h4"
              color="text.primary"
              sx={{
                fontFamily: 'MyFont',
                fontSize: { xs: '24px', sm: '28px', md: '35px' },
                fontWeight: 'bold',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              Quem é a CyberSensei?
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                mt: 2,
                mb: { xs: 2, sm: 3 },
                fontSize: { xs: '16px', sm: '20px', md: '25px' },
                textAlign: 'justify',
              }}
              style={{ fontFamily: 'MyFont' }}
            >
              A Plataforma CyberSensei democratiza a segurança cibernética para empresas de todos os tamanhos e
              segmentos. Capacitando os colaboradores a reconhecerem ameaças e adotarem práticas seguras, a plataforma
              usa recursos gamificados e educativos para engajar e educar de maneira eficaz, tornando sua equipe a
              primeira linha de defesa cibernética.
            </Typography>
            <Button
              id="SaibaMais"
              style={{ fontFamily: 'MyFont' }}
              variant="contained"
              color="primary"
              href="https://drive.google.com/file/d/193wBZvLasXMd1Ww1DyEG5-MNFkoZIfII/view"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 2,
                transition: 'background-color 0.3s ease, color 0.3s ease',
                fontSize: { xs: '14px', sm: '16px' }, // Tamanho da fonte responsivo
                '&:hover': {
                  backgroundColor: '#8b0e00',
                  color: 'white',
                },
              }}
            >
              Saiba Mais
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Features;
