import styled from 'styled-components';

// Estilos do contêiner do visualizador de documentos
export const DocumentViewerContainer = styled.div`
  max-width: 800px;
  margin: 5% auto;
  padding: 20px;
  font-family: 'MyFont', sans-serif;

  h2 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  h4 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }

  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    text-align: center;
    color: #555;
  }

  ul {
    font-family: 'MyFont';
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-family: 'MyFont';
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px;

    h2 {
      font-size: 1rem;
    }

    h3 {
      font-size: 1.3rem;
    }

    li {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
// Estilos para os Cards
export const StyledCard = styled.div`
  font-family: 'MyFont';
  width: 30%; // Diminuindo a largura do card
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: transform 0.3s ease, box-shadow 0.3s ease; // Adicionando transições para suavizar o efeito

  &:hover {
    transform: translateY(-10px); // Move o card para cima quando o mouse passa sobre ele
    box-shadow: 0 8px 16px rgba(174, 17, 0, 0.5);
  }

  h3 {
    font-size: 1rem;
    font-family: 'MyFont';
    text-align: center;
    color: #333;
  }

  p {
    font-size: 0.875rem;
    color: #555;
    font-family: 'MyFont';
    text-align: center;
    margin: 10px 0;
  }

  .button {
    font-family: 'MyFont';
    padding: 8px 12px;
    width: 100%;
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    background-color: #ae1100;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto; // Empurra o botão para o final do card
  }

  @media (max-width: 768px) {
    width: 200px; // Ajustando para dispositivos móveis
    height: 250px; // Ajuste para manter um tamanho proporcional no card em telas pequenas
  }
`;
