import styled from 'styled-components';

export const Popup = styled.div`
  background-color: white;
  display: flex;
  width: 80vh;
  height: 85vh;
  align-items: flex-start; /* Alinha os itens ao topo */
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  ::-webkit-scrollbar {
    width: 0px; /* Define a largura da barra de rolagem como 0 para escondê-la */
    background: transparent; /* Define o fundo da área de rolagem como transparente */
  }

  button {
    background-color: #ae1100;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: auto;
  }

  h1 {
    margin: -35px 0px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  .user-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    transition: background-color 0.3s, transform 0.3s; /* Transição suave para o hover */
  }

  .user-item:hover {
    background-color: #f0f0f0; /* Cor de fundo durante o hover */
  }

  .span {
    margin-right: auto;
  }
  h4,
  p,
  textarea,
  .text-field {
    width: 100%;
    padding: 2px;
    font-size: 15px;
  }

  .Button {
    position: absolute;
    top: 20px; /* Ajusta a altura do botão de fechar */
    right: 20px;
    cursor: pointer;
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
    min-width: 580px;
    min-height: auto;
    margin-bottom: -20px;
    flex-direction: column;
    display: flex;
    position: relative;
    max-height: calc(915vh - 500px);
    top: 20px; /* Ajusta a posição do box-container */
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 60%;
    background-color: white;
  }

  /* Media Queries para telas menores */
  @media (max-width: 768px) {
    width: 90vw; /* Ajusta a largura para telas menores */
    height: 80vh; /* Ajusta a altura para telas menores */
    padding: 10px; /* Ajusta o padding para telas menores */
    .box-container {
      min-width: 100%; /* Ajusta a largura mínima para telas menores */
      margin-bottom: 0; /* Remove a margem inferior */
    }
    h1 {
      font-size: 20px; /* Ajusta o tamanho da fonte do título */
    }
    button {
      width: 120px; /* Ajusta a largura do botão para telas menores */
      padding: 8px 16px; /* Ajusta o padding do botão */
    }
  }

  @media (max-width: 480px) {
    width: 95vw; /* Ajusta a largura para telas muito pequenas */
    height: 70vh; /* Ajusta a altura para telas muito pequenas */
    .box-container {
      padding: 10px; /* Ajusta o padding do box-container */
    }
    h1 {
      font-size: 18px; /* Ajusta o tamanho da fonte do título */
    }
    button {
      width: 100px; /* Ajusta a largura do botão para telas muito pequenas */
      padding: 6px 12px; /* Ajusta o padding do botão */
    }
  }
`;
