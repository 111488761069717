import styled from 'styled-components';

export const DocumentManagementContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;

  h2 {
    text-align: center;
    margin-bottom: 10%;
  }

  h3 {
    margin-top: 20px;
  }

  .button {
    margin-bottom: 10px;
    padding: 8px 12px;
    width: 150px;
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #8c0900; /* Cor do fundo ao passar o mouse para outros botões */
  }

  /* Estilos para o botão de deletar */
  .delete-button {
    display: flex;
    color: #696969; /* Cor do ícone */
    border: none; /* Sem borda */
    cursor: pointer; /* Mantenha o cursor como pointer */
    margin-left: 10px; /* Espaçamento à esquerda do ícone */
  }

  /* Remove o efeito de hover */
  .delete-button:hover {
    background-color: transparent; /* Nenhuma alteração de cor no hover */
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: flex; /* Alinha o conteúdo horizontalmente */
    align-items: center; /* Centraliza verticalmente os itens */
    margin-bottom: 8px; /* Espaçamento entre os itens da lista */
    overflow: hidden; /* Esconde o texto que ultrapassar a largura do li */
  }

  li a {
    color: #ae1100;
    text-decoration: underline;
    white-space: nowrap; /* Impede a quebra de linha no link */
    overflow: hidden; /* Esconde o texto que ultrapassa o contêiner */
    text-overflow: ellipsis; /* Adiciona "..." quando o texto é cortado */
    max-width: calc(100% - 40px); /* Ajuste a largura máxima (ajuste conforme necessário) */
  }
  .text-field {
    margin-bottom: 5%;
    font-family: 'MyFont';
    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
`;
