import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Composition = styled.div`
  max-height: 90vh;
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1px;
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: -5px;
    font-family: 'MyFont';
  }
  .logo {
    width: 100%;
    max-width: 490px; /* Limite a largura máxima */
    height: auto;
    margin: -70px;
    margin-bottom: -20%;
  }
  .password-checklist {
    height: 50%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1px;

  h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
  }

  .text-field {
    margin-top: 3%;
    width: 87%;
    font-family: 'MyFont';

    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
  }

  .text-field-password {
    width: 57%;
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: 'MyFont';

    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }

  button:not(.button-eye) {
    margin-top: 10px;
    width: 87%;
    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    padding: 10px 0; /* Adicione padding para aumentar a altura */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
    @media (max-width: 1024px) {
      width: 90%; /* Ajuste a largura para 100% em telas menores */
    }
  }
  input {
    height: 40px;
    font-size: 18px;
    padding: 0 10px;
    border-radius: 4px;
    margin: 10px;
  }
  select {
    width: 250px;
    height: 40px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 35%;
  }
  .input-label {
    font-family: 'MyFont';
  }

  .enviar {
    margin-top: 10%;
    color: #ae1100;
    font-family: 'MyFont';
  }
  .password-checklist {
    max-width: 85%;
    @media (max-width: 385px) {
      width: 50vh;
    }
  }
`;
export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff; /* Fundo branco */
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0; /* Mantém abaixo de Nav e SecondNav */
`;
