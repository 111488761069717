import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Box, Modal, Slider, Button } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { FcAddImage } from 'react-icons/fc';
import { Container } from '../../styles/GlobalStyles';
import { Containered } from './styled';
import axios from '../../services/axios';

const boxStyle = {
  width: '300px',
  height: '300px',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function CropperModal({ src, modalOpen, setModalOpen, setPreview, setCanSave }) {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  const handleSave = async () => {
    if (cropRef.current) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      setCanSave(true);
      setModalOpen(false);
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <AvatarEditor
          ref={cropRef}
          image={src}
          style={{ width: '100%', height: '100%' }}
          border={50}
          borderRadius={150}
          scale={slideValue / 10}
          rotate={0}
        />

        <Slider
          min={10}
          max={50}
          sx={{
            margin: '0 auto',
            width: '80%',
            color: '#ae1100',
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            border: '3px solid white',
            background: 'black',
          }}
        >
          <Button
            size="small"
            sx={{
              marginRight: '10px',
              color: 'white',
              borderColor: 'white',
              fontFamily: 'MyFont',
              '&:hover': {
                backgroundColor: '#ae1100', // hover vermelho
              },
            }}
            variant="outlined"
            onClick={() => setModalOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              background: '#ae1100',
              fontFamily: 'MyFont',
              '&:hover': {
                backgroundColor: '#ae1100', // hover vermelho mais claro
              },
            }}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

CropperModal.propTypes = {
  src: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  setCanSave: PropTypes.func.isRequired,
};

CropperModal.defaultProps = {
  src: null,
};

function Cropper() {
  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [canSave, setCanSave] = useState(false); // Inicialmente desativa o botão
  const inputRef = useRef(null);
  const id = useSelector((state) => state.auth.user.id);

  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    const loadAvatar = async () => {
      try {
        const response = await axios.get('/userimg');
        const userImgs = response.data;

        const userImg = userImgs.find((img) => img.idUser === id);
        if (userImg && userImg.img) {
          setPreview(userImg.img);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadAvatar();
  }, [id]);

  const handleSend = async () => {
    if (!canSave) return;

    const responsePreview = await fetch(preview);
    const blob = await responsePreview.blob();
    const file = new File([blob], 'profile.jpg', { type: blob.type });

    const formData = new FormData();
    formData.append('idUser', id);
    formData.append('img', file);

    try {
      const existingImgResponse = await axios.get('/userimg');
      const userImgs = existingImgResponse.data;
      const existingUserImg = userImgs.find((img) => img.idUser === id);

      let response;

      if (existingUserImg) {
        response = await axios.put(`/userimg/${existingUserImg.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        localStorage.setItem('showToast', 'true');
        window.location.reload();
      } else {
        response = await axios.post('/userimg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        localStorage.setItem('showToast', 'true');
        window.location.reload();
      }

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
    setCanSave(false);
  };

  useEffect(() => {
    if (localStorage.getItem('showToast') === 'true') {
      toast.success('Avatar alterado com sucesso!');
      localStorage.removeItem('showToast');
    }
  }, [id]);

  return (
    <Container>
      <Containered>
        <header>
          <h1>Modificar Avatar</h1>
          <hr />
        </header>
        <main className="container">
          <CropperModal
            modalOpen={modalOpen}
            src={src}
            setPreview={setPreview}
            setModalOpen={setModalOpen}
            setCanSave={setCanSave}
          />
          <div className="upload-container">
            <label htmlFor="upload-image" style={{ cursor: 'pointer' }}>
              <span
                onClick={handleInputClick}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') handleInputClick(e);
                }}
                role="button"
                tabIndex={0}
                aria-label="Upload Image"
              >
                <FcAddImage className="add-icon" />
              </span>
              <small>Clique aqui para selecionar a imagem</small>
              <input
                id="upload-image"
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImgChange}
                style={{ display: 'none' }}
              />
            </label>
          </div>
          <div className="img-container">
            <img
              src={preview || 'https://www.signivis.com/img/custom/avatars/member-avatar-01.png'}
              alt="Profile preview"
              width="200"
              height="200"
            />
          </div>
          <button type="button" onClick={handleSend} disabled={!canSave}>
            Salvar
          </button>
        </main>
      </Containered>
    </Container>
  );
}

export default Cropper;
