import styled from 'styled-components';

// Animação de fumaça
export const Termos = styled.div`
  overflow: hidden;
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  z-index: 1;

  @media (max-width: 1024px) {
    max-width: 100vw;
  }

  @media (max-width: 1024px) {
    max-width: 1024vw;
  }
  background: #fff;

  .content {
    margin-top: 5%;
  }

  iframe {
    height: 100vh;
  }
`;
