import React, { useState, useEffect } from 'react';
import { Button, CardActions, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import { Document, Page, View, Text, PDFDownloadLink, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container } from '../../styles/GlobalStyles';
import { StyledCard, CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import Background from '../../assets/img/background.png';
import Loading from '../../components/Loading';

const styles = {
  page: { flexDirection: 'column', backgroundColor: '#fff', color: '#000', padding: 50 },
  content: { flexDirection: 'column', padding: 12 },
  title: { fontSize: 24, textAlign: 'center', marginTop: 30, fontWeight: 'bold' },
  section: { margin: 5, padding: 5, fontSize: 14, textAlign: 'left' },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  tableCol: { width: '20%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
  tableCell: { margin: 'auto', marginTop: 5, fontSize: 12 },
  footer: { fontSize: 12, textAlign: 'center', marginTop: 30, fontWeight: 'light' },
};

function MyDocument({ statusReport, distribuicaoPorSetorEFuncao }) {
  const totalIniciaram = statusReport.iniciaram || 0;
  const totalNaoIniciaram = statusReport.naoIniciaram || 0;
  const totalConcluidos = statusReport.concluiram || 0;
  const taxaConclusao = ((totalConcluidos / (totalIniciaram + totalNaoIniciaram)) * 100).toFixed(2);
  const taxaConclusaoDisplay = Number.isNaN(parseFloat(taxaConclusao)) ? '0.00' : taxaConclusao;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.backgroundContainer}>
          <Image style={styles.backgroundImage} src={Background} />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>Relatório de Status do Treinamento</Text>
          <Text style={styles.title}>CyberSensei</Text>
          <Text style={styles.section}>Resumo Executivo</Text>
          <Text style={styles.section}>
            Este relatório apresenta o status do treinamento de conscientização em cibersegurança realizado através da
            plataforma CyberSensei.
          </Text>
          <Text style={styles.section}>Status do Treinamento dos Colaboradores</Text>
          <Text style={styles.section}>Iniciaram o Treinamento: {totalIniciaram}</Text>
          <Text style={styles.section}>Não Iniciaram: {totalNaoIniciaram}</Text>
          <Text style={styles.section}>Concluíram: {totalConcluidos} </Text>
          <Text style={styles.section}>Taxa de Conclusão: {taxaConclusaoDisplay}%</Text>{' '}
          <Text style={styles.section}>
            <strong>Distribuição por Setor e Função</strong>
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Setor</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Função</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Iniciaram</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Não Iniciaram</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Concluíram</Text>
              </View>
            </View>
            {Object.entries(distribuicaoPorSetorEFuncao).map(([setor, funcoes]) =>
              Object.entries(funcoes).map(([funcao, dados]) => (
                <View key={`${setor}-${funcao}`} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{setor}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{funcao}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{dados.iniciaram}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{dados.naoIniciaram}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{dados.concluiram}</Text>
                  </View>
                </View>
              )),
            )}
          </View>
          <Text style={styles.footer}>Conclusão</Text>
          <Text style={styles.footer}>
            O treinamento de conscientização em cibersegurança é uma etapa crucial para fortalecer a postura de
            segurança da organização.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

MyDocument.propTypes = {
  statusReport: PropTypes.shape({
    iniciaram: PropTypes.number.isRequired,
    naoIniciaram: PropTypes.number.isRequired,
    concluiram: PropTypes.number.isRequired,
  }).isRequired,
  distribuicaoPorSetorEFuncao: PropTypes.objectOf(
    PropTypes.shape({
      iniciaram: PropTypes.number.isRequired,
      naoIniciaram: PropTypes.number.isRequired,
      concluiram: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default function CourseReport() {
  const id = useSelector((state) => state.auth.user.id);
  const [courses, setCourses] = useState([]);
  const [statusReport, setStatusReport] = useState({});
  const [distribuicaoPorSetorEFuncao, setDistribuicaoPorSetorEFuncao] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [access, setAccess] = useState([]);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);

  useEffect(() => {
    async function getCourses() {
      setIsLoading(true);
      console.log('CNPJ enviado:', cnpj);

      try {
        const response = await axios.get('/course');
        console.log('Cursos:', response.data);
        setCourses(response.data);
      } catch (error) {
        console.error('Erro ao carregar cursos:', error);
      } finally {
        setIsLoading(false);
      }
    }

    getCourses();
  }, []);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/usercourse');
        const courseAccess = response.data.filter(
          (UserCourse) => UserCourse.userId && UserCourse.userId === Number(id),
        );
        setAccess(courseAccess);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const handleClick = async (courseId) => {
    setIsLoading(true);
    setShowDownloadButton((prev) => ({ ...prev, [courseId]: false }));
    try {
      const response = await axios.post('/display', { courseId, cnpj });
      console.log('Relatório:', response.data);
      setStatusReport((prev) => ({ ...prev, [courseId]: response.data.statusColaboradores }));
      setDistribuicaoPorSetorEFuncao((prev) => ({ ...prev, [courseId]: response.data.distribuicaoPorSetorEFuncao }));
      setShowDownloadButton((prev) => ({ ...prev, [courseId]: true }));
    } catch (error) {
      console.error('Erro ao buscar relatório:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Container>
      <CollaboratorContainer>
        <Grid container spacing={1} style={{ gap: '1px' }}>
          {courses
            .filter((course) => access.some((ac) => ac.courseId === course.id && ac.hasAccess))
            .map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id}>
                <StyledCard variant="outlined">
                  <CardMedia component="img" alt={course.name} height="150" image={course.img} className="img" />
                  <CardContent>
                    <Typography variant="h6" style={{ fontFamily: 'MyFont' }}>
                      {course.name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {!showDownloadButton[course.id] && (
                      <Button className="report-button" onClick={() => handleClick(course.id)}>
                        Gerar Relatório
                      </Button>
                    )}
                    {showDownloadButton[course.id] && (
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            statusReport={statusReport[course.id]}
                            distribuicaoPorSetorEFuncao={distribuicaoPorSetorEFuncao[course.id]}
                          />
                        }
                        fileName="relatorio.pdf"
                      >
                        {({ loading }) => (
                          <Button
                            className="button"
                            sx={{
                              width: { xs: '100%', sm: '200px', md: '280px' },
                              padding: { xs: '8px', sm: '10px' },
                            }}
                            disabled={loading}
                          >
                            {loading ? 'Carregando...' : 'Baixar PDF'}
                          </Button>
                        )}
                      </PDFDownloadLink>
                    )}
                  </CardActions>
                </StyledCard>
              </Grid>
            ))}
        </Grid>
      </CollaboratorContainer>
    </Container>
  );
}
