import { Button } from '@material-ui/core'; // Importação de pacotes externos deve vir primeiro
import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .invalid-label {
    color: red;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin: 5px;
    margin-left: 15px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 15px 1px;
    font-size: 18px;
    margin-bottom: 2%;
  }

  .password-checklist {
    font-family: 'MyFont';
    border-color: #ae1100;
    label {
      font-family: 'MyFont';
      display: flex;
      border-color: #ae1100;
      font-size: 15px;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  input,
  select {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  @media (max-width: 768px) {
    input,
    select {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    input,
    select {
      width: 100%;
    }
  }
  .text-field {
    font-size: 15px;
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 75%;
    background-color: white;
  }
  .autocomplete {
    font-family: 'MyFont', sans-serif !important;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 3.2%;
      font-family: 'MyFont' !important;
      font-size: 15px; /* Adicionado tamanho da fonte */
    }
    .MuiAutocomplete-popupIndicator {
      position: absolute;
      font-family: 'MyFont', sans-serif !important;
      right: 65px; /* ajuste este valor conforme necessário */
    }
    .MuiInput-underline:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:after {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete .MuiInput-underline:before,
    .autocomplete .MuiInput-underline:after,
    .autocomplete .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete button,
    .autocomplete button:hover {
      font-family: 'MyFont', sans-serif !important;
    }

    .autocomplete .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }

    .autocomplete .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }

    .autocomplete input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
    }

    button {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    button:hover {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      max-height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }
    .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 10px !important;
  margin-top: 20px !important;
  width: 250px !important;
  color: white !important;
  font-weight: bold !important;
  background-color: #ae1100 !important;
  font-family: 'MyFont' !important;
  z-index: 1000 !important;
  text-transform: none !important;
  &:hover {
    background-color: #ae1100 !important;
    transition-duration: 0s !important;
  }
`;
